import React from 'react';
import styled from 'styled-components';
import './MicroControllers.css';
import { useState, useEffect } from 'react';

const MicroControllers = () => {

    const [hoveredIndex, setHoveredIndex] = useState(null);


    const Wrapper = styled.div`
    @media only screen and (min-width: 600px) {

 opacity: ${({ index, hoveredIndex }) => {
            console.log('index:', index);
            console.log('hoveredIndex:', hoveredIndex);
            return hoveredIndex === null || hoveredIndex === index ? 1 : 0.1;
        }};
  transition: opacity 0.3s ease;
  }
      @media only screen and (max-width: 600px) {
        transform: scale(0.8);
      }

      width: 270px;
      height: 540px;
      perspective: 2200px;
      position: relative;
      margin-left: 14px;
      margin-right: 90px;
      float: left;
    `;



    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };


    
    return (
        <>
            <h2 className="mainheader">Boards Iv worked With</h2>
            <div className="coolcontainer">
                <Wrapper
                    index={0}
                    hoveredIndex={hoveredIndex}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="card">
                        <div className="front">
                            <h1>Pico</h1>
                            <p>micro controller</p>
                            <p><span>2021</span></p>
                            <p className="price"> 3-5 pounds</p>
                        </div>
                        <div className="right">
                            <h2>Pi Pico</h2>
                            <p>
                                From controlling appliances to operating a light display, Raspberry Pi Pico puts the technology that underpins countless everyday operations into your hands.
                            </p>
                            <p>
                                Programmable in C and MicroPython, Pico is adaptable to a vast range of applications and skill levels, and getting started is as easy as dragging and dropping a file.
                            </p>
                            <a href='/Tutorials/pico'>
                                <button className="itembutton">Tutorial</button>
                            </a>
                        </div>
                    </div>
                    <div className="img-wrapper">
                        <img src='/picotransform.png' className="moveimg" alt='raspberry pi pico' />
                    </div>
                </Wrapper>
                <Wrapper
                    index={1}
                    hoveredIndex={hoveredIndex}
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="card">
                        <div className="front">
                            <h1>Audino Nano</h1>
                            <p>micro controller</p>
                            <p><span>2021</span></p>
                            <p className="price"> 3-5 pounds</p>
                        </div>
                        <div className="right">
                            <h2>aurdino nano</h2>
                            <p>
                                Small but powerfull micro controller
                                runs on very low power intake and is
                                great for small projects.
                            </p>
                            <a href='/Tutorials/aurdinonano'>
                                <button className="itembutton">Tutorial</button>
                            </a>
                        </div>
                    </div>

                    <div className="img-wrapper">
                        <img src='/nanotransform.png' className="moveimg" alt='Aurdino nano' />
                    </div>
                </Wrapper>
                <Wrapper
                    index={2}
                    hoveredIndex={hoveredIndex}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="card">
                        <div className="front">
                            <h1>Pi 4</h1>
                            <p>micro Computer</p>
                            <p><span>2021</span></p>
                            <p className="price"> 35-70 pounds</p>
                        </div>
                        <div className="right">
                            <h2>Pi</h2>
                            <p>
                                A powerfull and small computer can run smart robots or systems, or act as a server a one fits all computer
                            </p>
                            <p>
                                Programmable in most programming languages, and has gpio interfaces a really handy board.
                            </p>
                        </div>
                    </div>
                    <div className="img-wrapper">
                        <img src='/raspberry-pi-4.png' className="moveimg" alt='Raspberry pi4' />
                    </div>
                </Wrapper>
                <Wrapper
                    index={3}
                    hoveredIndex={hoveredIndex}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="card">
                        <div className="front">
                            <h1>Esp32 Cam</h1>
                            <p>micro controller with embeded camera</p>
                            <p className="price"> 3-5 pounds</p>
                        </div>
                        <div className="right">
                            <h2>Esp-32</h2>
                            <p>
                                works with c and micro python, one of the cheapest boards that included a camera and wifi/bluetooth.
                            </p>
                            <a href='/Tutorials/pico'>
                                <button className="itembutton">Tutorial</button>
                            </a>
                        </div>
                    </div>
                    <div className="img-wrapper">
                        <img src='/esp-32.png' className="moveimg" alt='esp-32' />
                    </div>
                </Wrapper>
            </div>
        </>

    );
};

export default MicroControllers;
