import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    const value = {
        token,
        setToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
