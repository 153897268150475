import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';

const StyledNav = styled.nav`
  background-color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: relative;

  .logo {
    img {
      height: 60px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
    &.active {
      color: #50d890;
    }
  }

  .menu {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  .burger {
    display: none;
    appearance: none;
    background: transparent;
    border: none;
    color: #398b82;
    cursor: pointer;
    font-size: 24px;
    padding: 8px;
  }

  .burger:hover {
    color: #156f66;
  }

  .burger:focus {
    outline: none;
  }

  .burger::after {
    content: '';
    display: block;
    height: 2px;
    background-color: #398b82;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    .burger {
      display: inline-block;
      width: 10%;
    }

    .logo {
      width: 30%;
    }

    .menu {
      display: ${props => (props.open ? 'flex' : 'none')};
      flex-direction: column;
    }
  }
`;

const Underline = styled.div`
  background: #398b82;
  height: 6px;
  width: 100%;
`;

const Navbar = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <StyledNav open={open}>
                <div className="logo">
                    <NavLink to="/" end onClick={() => setOpen(false)}>
                        <img src="/icon.png" alt="" />
                    </NavLink>
                </div>
                <div className="menu">
                    <NavLink to="/" end onClick={() => setOpen(false)}>
                        Home
                    </NavLink>
                    <NavLink to="/Posts" onClick={() => setOpen(false)}>
                        Posts
                    </NavLink>
                    <NavLink to="/Projects" onClick={() => setOpen(false)}>
                        Projects
                    </NavLink>
                    {/* <NavLink to="/about" onClick={() => setOpen(false)}>
                        About Me
                    </NavLink> */}
                    <NavLink to="/Live-Examples" onClick={() => setOpen(false)}>
                        Live examples
                    </NavLink>
                </div>
                <button className="burger" onClick={() => setOpen(!open)}>
                    ☰
                </button>
            </StyledNav>
            <Underline />
            <link rel="canonical" href="https://jerboaworkshop.com" />
        </>
    );
};

export default Navbar;
