import React, { useEffect, useRef, useState } from 'react';
import { createWorker } from 'tesseract.js';
import './tessex.css'

const vidWidth = 400;
const vidHeight = 260;
const vidOffsetTop = 240;
const vidOffsetLeft = ((window.innerWidth) / 2) - (vidWidth / 2);

const marginX = 5;
const indWidth = vidWidth - marginX;
const indHeight = 150;
const indOffsetTop = vidOffsetTop + (vidHeight / 2) - (indHeight / 2);
const indOffsetLeft = (window.innerWidth / 2) - (indWidth / 2);

export default function Tessexample() {
    const myVideo = useRef();
    const myStream = useRef();
    const scannedCodes = useRef();
    const [scannedText, setScannedText] = useState("");
    const [worker, setWorker] = useState(null);

    useEffect(() => {
        (async () => {
            const worker = await createWorker();
            await worker.loadLanguage('eng');
            await worker.initialize('eng');
            setWorker(worker);
        })();
    }, []);

    useEffect(() => {
        if (myVideo && myVideo.current) {
            navigator.mediaDevices.getUserMedia({
                video: { facingMode: "environment" },
                audio: false
            })
                .then(stream => {
                    myVideo.current.srcObject = stream;
                    var playPromise = myVideo.current.play();
                    if (playPromise !== undefined) {
                        playPromise.then(_ => { })
                            .catch(error => { });
                    }
                    myStream.current = stream;
                    scannedCodes.current = {};

                    const canvas = document.createElement("canvas");
                    canvas.width = indWidth;
                    canvas.height = indHeight;

                    const tick = async () => {
                        if (myVideo && myVideo.current && worker) {
                            const image = myVideo.current;
                            const sx = (marginX ) ;
                            const sy = vidHeight - indHeight;
                            const sWidth = indWidth ;
                            const sHeight = indHeight;
                            const dx = 0;
                            const dy = 0;
                            const dWidth = indWidth;
                            const dHeight = indHeight;

                            canvas.getContext("2d").drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

                            const { data: { text } } = await worker.recognize(canvas);
                            // const regex = /[a-zA-Z0-9]/gi;
                            const scannedTextResult = text; // && text.match(regex) && text.match(regex).filter(x => x).join("");
                            setScannedText(scannedTextResult);
                        }
                        setTimeout(tick, 1000);  // delay in milliseconds
                    };
                    tick();
                })
                .catch(err => {
                    console.error(err);
                })
        }

        return () => myStream && myStream.current && myStream.current.getTracks().forEach(x => x.stop());
    }, [worker]);

    return (
        <div className="ProjectDocument" style={{
            width: "100%"
        }}>
            <h1 style={{
                marginTop: "90px"
            }}> Text recognition example</h1>
            <video
                ref={myVideo}
                autoPlay
                muted
                playsInline
                width={vidWidth}
                height={vidHeight}
                style={{
                    position: "absolute",
                    top: vidOffsetTop,
                    left: vidOffsetLeft,
                    zIndex: 2
                }}
            ></video>
            <div
                style={{
                    width: vidWidth,
                    height: indHeight,
                    border: "1px red solid",
                    zIndex: 3,
                    position: "absolute",
                    top: indOffsetTop,
                    left: indOffsetLeft
                }}
            ></div>
            <h3>dont fear the image is running locally not being sent to a server</h3>
            <div>texting being read: <span>{scannedText}</span></div>
            <h1 style={{
                marginTop: "280px"
            }}> Uses of this</h1>
            <p>
                Although what you see here is not very impressive it proves the concept of text recognition.
                The contrast is turned up on the image to help you see what the computer can see. the recognition
                on here is not perfect and the libarys for python ect are much better.
            </p>
            <h4>How have I used this</h4>
            <p>
                I find this is amazing for video games as reading text can be used in many ways.
                i have personally used it in video games to aid in quizes on mobile games, or
                in much more complicated systems such as a Discord alert bot that reads logs for a game and
                will use a webhook api to warn people if they are under attack in a game.

            </p>
            <hr />
            <h1> Three js example</h1>
            <p>This is an example of using three js in my react website.
                This is just a simple canvas that shows a animated model but
                shows how it can be intergrated into a website without performace issues. </p>
        </div>
    )
};