import React from 'react';
//import './home.css';
import './HomePage.css';
import { ParaMain } from '../ParralaxMainscreen/ParalaxMain';
import { FocusBox } from '../Components/Focus box/FocusBox';
import MicroControllers from '../Components/MicroControllers/MicroControllers'
export function Home() {

    return (
        <div className="mainstyle1">
            <ParaMain />
            <FocusBox colour={"#398b82"} >
                <h1> Welcome to my website</h1>
                <div className="grid-container-split full">
                    <div>
                        <p>This website, created by Callum Terrill, serves as a source of fun and showcases my work portfolio. Here, you'll find a diverse range of content, including personal projects, skills demonstrations, game guides, and examples of various projects.</p>

                        <p>While this website is primarily a platform for me to share things I find enjoyable, it may not have a strictly professional or polished aesthetic. Instead, it reflects my passion for exploring and showcasing what I find fun and interesting.</p>

                        <p>As someone with a background in software engineering, I have encountered numerous challenges throughout my projects. While some of these projects may already be available online, I have had to experiment and discover solutions on my own, particularly in more complex endeavors. Through the tutorials and documentation provided on this site, I aim to share my experiences and offer guidance on overcoming these challenges. You can expect to find a variety of projects here, and I may delve into more intricate software ventures depending on my interests and inspiration.</p>

                        <p>Thank you for visiting my website, and I hope you enjoy exploring the content I have curated. Feel free to reach out if you have any questions or feedback!</p>
                    </div>
                    <img className="full whatissiteimg" src="/callum.png" width="90%" alt="callum sipping coffee with a fox holding a usb on his shoulder" />
                </div>
            </FocusBox>

            <div className=" tutorial-page" id='pagehtml'>

                <MicroControllers />
                <FocusBox colour={"#398b82"} >
                    <h1 >Example Of A Project</h1>
                    <div className="grid-container-split full">
                        <div>
                            <p >I recently explored the world of AI-generated QR codes and decided to give it a try myself. To my surprise, the setup process was incredibly straightforward. However, I have noticed that these QR codes sometimes encounter issues when scanning. Fortunately, I discovered a helpful solution using Photoshop. By overlaying the original QR code at a lower opacity, I've managed to enhance their functionality effectively.</p>

                        </div>
                        <img className="full whatissiteimg" src="/JerboaQr.png" width="70%" alt="Jerboa with a qr code overlay made using AI" />
                    </div>
                </FocusBox>
            </div>


        </div>
    );
}
