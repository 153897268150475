import * as React from 'react';
import './Posts.css';
import './box.css';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function Posts() {
    const [posts, setPosts] = React.useState([]);
    let { PageName } = useParams();

    React.useEffect(() => {
        const fetchPosts = async () => {
            const response = await fetch("/api/PostsList");
            const data = await response.json();
            setPosts(data);
        };

        fetchPosts();
    }, []);

    if (PageName) {
        let filteredPost = posts.filter((post) => {
            return post.id === PageName;
        });

        return (
            <div style={{ width: "100%" }}>
                {filteredPost.map(post =>
                    <div>
                        <div className="Posts">
                            <div className="Postscontainer">
                                <iframe className="Postschild" title="Post" src={post.pagename}></iframe>
                            </ div>
                        </ div>
                    </div>
                )}
            </div >
        );

    } else {
        return (
            <div className="tutorials">
                <br></br><br></br><br></br>
                <h2 className="mainheader">All Posts</h2>
                <div class="PostsInfoContainer">
                {posts.map(home =>
                    <div key={home.pagename} className="view view-ninth">
                        <Link className="text-dark" to={`${home.id}`} >
                            <img src={home.iconUrl} alt={home.pagename} width="100%" />
                            <div className="mask mask-1"></div>
                            <div className="mask mask-2"></div>
                            <div className="content">
                                <h2>{home.title}</h2>
                                <p>{home.description}</p>
                            </div>
                        </Link>
                    </div>
                    )}
                </div>
            </div>
        );
    }
}
