import React from 'react';
import styled from 'styled-components';

const AboutMeContainer = styled.div`
    background-color: #282c34;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
`;

const AboutMeHeader = styled.h2`
    color: #61dafb;
`;

const AboutMeParagraph = styled.p`
    margin: 10px 0;
`;

const AboutMe = () => {
    return (
        <>
            <AboutMeContainer className="about-me">
                <AboutMeHeader>About Me</AboutMeHeader>
                <AboutMeParagraph>My name is Callum Terrill, a software engineer based in Bristol. I bring 6 years of formal education in programming and 2.5 years of professional experience to the table. I have a well-rounded understanding of several programming languages including JavaScript, C#, and Python, and I am familiar with various frameworks such as React.js, Redux, and ASP.NET. I have also worked extensively with test-driven development, utilizing tools like Pytest for Python and NUnit for C#. My experience extends to server management, security, and project testing.</AboutMeParagraph>
                <AboutMeParagraph>In my previous roles, I have held positions in both recreational and software engineering fields, honing my teamwork skills and gaining invaluable experience in website development, server management, and project testing. Currently, I am a
                    Software Engineer in trowbridge.</AboutMeParagraph>
                <AboutMeParagraph>Outside of my professional life, I have a keen interest in robotics and have worked with various motors and sensors. I am also first aid trained from my time as a lifeguard. I am a proactive and dedicated individual, eager to bring my skills to a new challenge.</AboutMeParagraph>
            </AboutMeContainer>
            <div style={{ position: 'relative' }}>
                <svg style={{ position: 'absolute', top: 0, left: 0 }} width="100" height="100">
                    <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="red" />
                </svg>
                <svg style={{ position: 'absolute', top: 0, left: 0 }} width="100" height="100">
                    <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="blue" />
                </svg>
            </div>
            <div style={{
                width: '100%', height: '100vh', overflow: 'hidden'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 900">
                    <g class="beige_path">
                        <path fill="none" stroke="#f9f4da" stroke-miterlimit="10" stroke-width="70" d="M55.02 678.9v-10.02c0-179 147-190.61 193.6-194.65 46.6-4.04 123.39-15.83 422-15.83s317.66-194.63 235.24-280.28c-82.42-85.66-330.6-109.35-559.81-67.08-219.92 40.55-194.35 246.89 7.09 214.89"></path>
                    </g>
                </svg>
            </div>
        </>

    );
};

export default AboutMe;
