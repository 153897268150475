import React from 'react';
import './ListItemBox.css';  // import the CSS file

function ListItemBox({ name, image, description }) {

    return (
        <div className='ListItemBox'>
            <img className='project-image' src={image} alt={description} />
            <div className='project-text'>
                <h1>{name}</h1>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default ListItemBox;
