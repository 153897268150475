import React, { useEffect, useState } from 'react';
import './SideBarNavigation.css';
function SidebarNavigation({ headings }) {
    return (
        <div className="sidebar">
            {headings.map((heading, index) => (
                <a key={index} href={`#${heading.id}`} class={`Sidebar-${heading.tagName}`}>
                    {heading.content}
                </a>
            ))}
        </div>
    );
}

export default SidebarNavigation;
