import React, { useState } from 'react';
import { useAuth } from './AuthContext';



const Login = () => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const { setToken } = useAuth();

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        //axios.post("authenticate"", { username, password })
        fetch('/Auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
            .then(response => response.json()) // Add this line to parse the response as JSON
            .then(data => {
                // Store the token in the frontend's state or in a cookie
                console.log(data);
                var token = data.token;
                setToken(token);
                console.log(token);
            })
            .catch(error => {
                setError('Invalid username or password');
            });
    };

    const handleRegisterSubmit = (event) => {
        event.preventDefault();
        fetch('/Auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password }),
        })
            .then(async response => {
                if (response.ok) {
                    // Redirect to the login or a confirmation page, etc.
                    const responseData = await response.json();
                    console.log(responseData);
                } else {
                    throw new Error('Registration failed');
                }
            })
            .catch(error => {
                setError('Registration failed');
            });
    };
    const { token } = useAuth();
    return (
        <div>
            <h2>Login</h2>
            {error && <div className="error">{error}</div>}
            <form onSubmit={handleLoginSubmit}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={handleUsernameChange} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </label>
                <button type="submit">Submit</button>
            </form>

            <h2>Register</h2>
            <form onSubmit={handleRegisterSubmit}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={handleUsernameChange} />
                </label>
                <label>
                    Email:
                    <input type="email" value={email} onChange={handleEmailChange} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </label>
                <button type="submit">Sign up</button>
            </form>
            <p>{token}</p>
        </div>
    );
};

export default Login;
