import * as React from 'react';
import { useParams } from 'react-router-dom';
import './projects.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import ListItemBox from '../ListItemBox/ListItemBox';
import SideBarNavigation from '../SideBarNavigation/SideBarNavigation';

export default function Projects() {
    const { topic, name } = useParams();

    const [nodes, setNodes] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [htmlContent, setHtmlContent] = useState('');
    const [headings, setHeadings] = useState([]);

    const getAllNodes = () => {
        axios.get('/Projects.json').then((response) => {
            setNodes(response.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        getAllNodes();
    }, []);


    useEffect(() => {
        if (name && nodes[topic]) {
            const category = nodes[topic];
            const project = category.find(p => p.name === name);

            if (project) {
                axios.get(project.url)
                    .then(response => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(response.data, 'text/html');
                        const headingElements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
                        const updatedHeadings = Array.from(headingElements).map((element, index) => {
                            const id = `heading-${index}`;
                            element.setAttribute('id', id);
                            return {
                                id,
                                tagName: element.tagName,
                                content: element.textContent
                            };
                        });

                        const serializer = new XMLSerializer();
                        const updatedHtml = serializer.serializeToString(doc);

                        setHtmlContent(updatedHtml);
                        setHeadings(updatedHeadings);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [name, topic, nodes]);

    if (name) {
        return (
            <div className="ProjectDocument">
                <SideBarNavigation headings={headings} />
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            </div>
        );
    }

    if (topic) {
        if (isLoading) {
            return <div className="App">Loading...</div>;
        }

        const url = nodes[topic];
        return (
            <div style={{ paddingTop: '90px' }}>
                {url.map((project) => (
                    <div key={project.key.toString()} id={project.key.toString()}>
                        <NavLink to={`/Projects/${topic}/${project.name.toString()}`}>
                            <ListItemBox
                                name={project.name.toString()}
                                image={project.image}
                                description={project.description}
                            />
                        </NavLink>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="projects-page">
            <NavLink to="/Projects/Robotics" id="robotics"></NavLink>
            <NavLink to="/Projects/general" id="content1">
                <div className="box-highlight-text">General Coding</div>
            </NavLink>
            <NavLink to="/Projects/websites" id="content2">
                <div className="box-highlight-text">Web Development</div>
            </NavLink>
            <NavLink to="/Projects/DiscordBots" id="content3">
                <div className="box-highlight-text">Discord Bots</div>
            </NavLink>
            <h1>Coming soon!</h1>
        </div>
    );
}


