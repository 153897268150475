import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import './ChatPage.css';

const ChatPage = () => {
    const [chats, setChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messageInput, setMessageInput] = useState('');

    const { token } = useAuth();

    useEffect(() => {
        fetchUserChats();
    }, []);

    const fetchUserChats = async () => {
        try {
            const response = await fetch('/Chat/GetUserChats', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setChats(data);
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
        }
    };

    const selectChat = async (chat) => {
        setSelectedChat(chat);
        // Replace the userId with the actual user id
        const response = await fetch(`/Chat/addmessage?message=&chatName=${chat.chatName}`);
        const data = await response.json();
        setMessages(data);
    };

    const handleMessageInputChange = (e) => {
        setMessageInput(e.target.value);
    };

    const sendMessage = async (e) => {
        console.log("sending message")
        e.preventDefault();
        if (!messageInput.trim()) return;
        const baseUrl = `/Chat/addmessage?message=${messageInput}`;
        const chatNameParam = selectedChat.chatName ? `&chatName=${selectedChat.chatName}` : '';
        const url = baseUrl + chatNameParam;
        const response = await fetch(url);
        const data = await response.json();
        setMessages([...messages, data]);
        setMessageInput('');
    };

    return (
        <div className="chat-page">
            <div className="chats-list">
                {chats.map((chat) => (
                    <div key={chat.chatId} className="chat-item" onClick={() => selectChat(chat)}>
                        {chat.chatName}
                    </div>
                ))}
            </div>
            <div className="messages-container">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender === 'User' ? 'user-message' : 'assistant-message'}`}>
                        {message.content}
                    </div>
                ))}
                <div className="message-input-container">
                    <form onSubmit={sendMessage}>
                        <input
                            type="text"
                            className="message-input"
                            placeholder="Type your message here"
                            value={messageInput}
                            onChange={handleMessageInputChange}
                        />
                        <button type="submit" className="send-message-button">Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChatPage;

/*
const ChatPage = () => {
   // fetch('/chat', {
    //    method: 'GET',
        // Other options if necessary
    //})


    const handlecallSecuredApi = (event) => {
        event.preventDefault();
        const jwtToken = token; // Replace this with the actual JWT token you received from the Authenticate function

        (async () => {
            try {
                const response = await fetch('/chat', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                } else {
                    console.error(`Error: ${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        })();
    }
    const { token } = useAuth();

    return (
        <div>
            <form onSubmit={handlecallSecuredApi}>
                <button type="submit">Call Secured API</button>
            </form>
        </div>
    )
}
export default ChatPage;

                //could you write me a user interface in react like the example but i want a bar on the left side showing all the chats */