import * as React from 'react';
import { Link } from 'react-router-dom';
import './FocusBox.css';

export function FocusBox(props) {
    React.useEffect(() => {
        function reveal() {
            var reveals = document.querySelectorAll(".reveal");

            for (var i = 0; i < reveals.length; i++) {
                var windowHeight = window.innerHeight;
                var elementTop = reveals[i].getBoundingClientRect().top;
                var elementVisible = 150;

                if (elementTop < windowHeight - elementVisible) {
                    reveals[i].classList.add("active");
                } else {
                    reveals[i].classList.remove("active");
                }
            }
        }
        window.addEventListener("scroll", reveal);
    })
    return (
        <div className="reveal" style={{backgroundColor:  props.colour } } >
            { props.children }
        </div>
    );
}