import React, { useState, memo } from 'react';
import Login from './Login';
import ChatPage from './ChatPage';
import WeatherForecast from './WeatherForcast/WeatherForecast';
import { WeatherContext } from './WeatherForcast/WeatherContext';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './NavigationBar/Navbar';
//import Home from './home';
import { Home } from './Homepage/Home.js';
import Posts from './Components/Posts/Posts.js';
import Projects from './Components/Projects/Projects.js';
import AboutMe from './Components/AboutMe/AboutMe.js';
import Tessexample from './Components/Live examples/Tesserect/Tessexample'

const App = () => {
    const [forecasts, setForecasts] = useState([]);

    return (
        <AuthProvider>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/forecast" element={
                        <WeatherContext.Provider value={[forecasts, setForecasts]}>
                            <WeatherForecast />
                        </WeatherContext.Provider>
                    } />
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/about" element={<AboutMe />} />
                    <Route path="/chat" element={<ChatPage />} />
                    <Route path="/Posts" element={<Posts />} />
                    <Route path='/Posts/:PageName' element={<Posts />} />
                    <Route path="/Projects" element={<Projects />} exact />
                    <Route path="/Projects/:topic" element={<Projects />} />
                    <Route path="/Projects/:topic/:name" element={<Projects />} />
                    <Route path="/Live-Examples" element={<Tessexample />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
